//
// Progress.scss
//

.pricing-box {
  .pricing-label {
    display: flex;
    margin-top: -5em;
    justify-content: flex-end;
    h5 {
      width: 60px;
      height: 60px;
      line-height: 60px;
      background: $primary;
      border-radius: 50%;
    }
  }
  h1 {
    font-size: 44px;
  }
  .pricing-features {
    p {
      border-bottom: 1px solid $gray-200;
      padding-bottom: 12px;
    }
  }

  @keyframes juggleEffect {
    0% {
    }
  }

  &:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-in-out;

    .text-white {
      transform: translate(180);
    }
  }

  transition: all 0.5s ease-in-out;
}
