.navItems {
  display: flex;
  justify-content: flex-end;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none !important;
  box-shadow: none !important;
}
@media (max-width: 992px) {
  .navItems {
    justify-content: flex-start;
    margin-top:1em;
  }
}
