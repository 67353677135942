.modal-style {
  .row {
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;

      .col-md-5 {
        width: auto;
        margin-bottom: 2em;
      }

      .col-md-7 {
        width: auto;
      }
    }
  }

  ul {
    list-style: none;

    li {
      margin-left: -1.7em;
      margin-bottom: 0.5em;
      font-size: 1.2em;
    }
  }
}
