/* Scroll Bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ccc9c9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #af22d3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #811a9b;
}

.home-hero {
  display: flex;
  justify-content: space-evenly;
  gap: 5em;

  @media (max-width: 991px) {
   gap: 0;
  }
  
  padding-top: 10%;
  @media (max-width: 991px) {
    padding-top: 13%;
  }
  .hero-image {
    flex: 1;
    display: flex;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes floatUpDown {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px); // Adjust the floating distance as needed
      }
    }
    .landing {
      margin-top: -3em;
      width: 37em;
      opacity: 0;
      animation: fadeIn 2.5s ease forwards, floatUpDown 2.5s ease-in-out infinite alternate; // Apply both animations
      @media (max-width: 1024px) {
        width: 30em;
      }
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}

.home-center {
  display: table;
  background-color: rgba(40, 75, 190, 0.884);
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-desc-center {
  display: table-cell;
  vertical-align: middle;
}

.home-content {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  .sub-title {
    opacity: 0;
    transform: translateX(-5%);
    animation: 1s fadeIn ease-out forwards;
  }

  .title {
    opacity: 0;
    transform: translateX(-5%);
    animation: 1s fadeIn ease-out forwards;
    font-size: 2.7em;

    @media (max-width: 1400px) {
      font-size: 2em;
    }
  }
}

.home-section {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  @media (max-width: 991px) {
    height: auto;
  }
}

@keyframes fadeUpButton {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.btn {
  font-size: 0.9rem;
  padding: 0.62em 1.4em;
  transition: all 0.4s;
  border-radius: 7px;
}

.btn-start {
  margin-right: 1em;
  padding: 0.61em 1em;
  background-color: rgb(255, 255, 255);
  border: 2px solid #00879E;
}

.btn-primary{
  border: 2px solid #ffb400;
}

.btn-primary:hover{
  background-color: #d49501 !important;
}

.btn-start:hover {
  background-color: #00879E;
  color: white;
}

.btn-purchase {
  transform: translateY(70%);
  opacity: 0;
  animation: 1s fadeUpButton forwards ease-out;
  background-color: rgb(10, 153, 70);
  border: 2px solid rgb(10, 153, 70);
  color: white;
}

.btn-purchase:hover {
  background-color: rgb(8, 114, 52);
  color: white;
}

.hiddenAnimation {
  opacity: 0;
  transform: translateY(5%);
  filter: blur(5px);
  transition: opacity 1.2s ease-in-out, transform 0.5s ease-in-out;
}

.showAnimation {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}
